export default {
  name: "ListTLoanAccountRestructurisation",
  computed: {
    officeCodeSession() {
      let getSession = JSON.parse(
        this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
      ).officeCode;
      this.property.filterDto.officeCode;
      return getSession;
    },
  },
  data() {
    return {
      identifier: {
        akadNumberOld: "",
        loanSp3Id: "",
        loanAccountIdOld: "",
      },
      dataForm: {
        checkingLoanAccount: {
          accountNumber: "",
          cifIdName: "",
        },
        checkingSp3: {
          cifIdName: "",
          sp3DocNumber: "",
          cifNumber: "",
        },
      },
      session: {
        checkingLoanAccount: {
          accountNumber: "",
          cifIdName: "",
        },
        checkingSp3: {
          cifIdName: "",
          sp3DocNumber: "",
          cifNumber: "",
        },
      },
      property: {
        animation: {
          checkingLoanAccount: {
            isLoading: false,
          },
          checkingSp3: {
            isLoading: false,
          },
        },
        modal: {
          showModalCheckingLoanAccount: false,
          showModalResultListLoanAccount: false,
          showModalCheckingSp3: false,
          showModalResultListLoanSp3: false,
        },
        listElement: {
          loanAccountRestructure: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
          loanAccount: {
            rows: 0,
            perPage: 5,
            currentPage: 1,
            message: "",
            downloading: false,
          },
          loanSp3: {
            rows: 0,
            perPage: 5,
            currentPage: 1,
            message: "",
            downloading: false,
          },
        },
        filterDto: {
          loanAccountNumber: "",
          cifIdName: "",
          dateFrom: "",
          officeCode: "",
          dateTo: "",
        },
      },
      table: {
        totalRekening: 0,
        totalNominatif: 0,
        data: {
          loanAccountRestructure: [],
          loanAccount: [],
          loanSp3: [],
        },
      },
      options: {
        cabang: [],
      },
    };
  },
  methods: {
    async deleteLoanAccountRestructure(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      this.$buefy.dialog.confirm({
        message: "Hapus Data Ini ?",
        type: "is-success",
        onConfirm: async () => {
          try {
            const resp = await this.$store.dispatch({
              type: "DELETE_DATA",
              endPoint: "loan",
              reqUrl: "transaction-loan/" + props.row.loanAccountId,
            });
            if (resp.data.code === "SUCCESS") {
              this.$buefy.toast.open(this.$NotificationUtils.success);
              setTimeout(() => {
                this.property.listElement.loanAccountReschedule.currentPage = 1;
                this.property.filterDto.loanAccountNumber = "";
                this.property.filterDto.dateFrom = "";
                this.property.filterDto.dateTo = "";
                this.property.filterDto.cifIdName = "";
                this.property.filterDto.officeCode = "";
                this.setDefaultOfficeValue();
                this.getLoanAccountRestructure();
              }, 1000);
            } else {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: resp.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      resp.data.errorFields
                    )
                  : resp.data.message,
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          } catch (error) {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: error.response
                ? error.response.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      error.response.data.errorFields
                    )
                  : error.response.statusText
                : "Terjadi Kesalahan",
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        },
      });
    },
    globalDirectiveCheckLoanInstallmentHistory(props) {
      sessionStorage.setItem(
        "LOAN_ACCOUNT_NUMBER",
        props.row.loanAccountNumber
      );
      this.$router.push("/report/history-angsuran/detail");
    },
    async searchLoanAccountRestructure() {
      this.table.data.loanAccountRestructure = [];
      this.property.listElement.loanAccountRestructure.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "transaction-loan/reschedule/simple-list",
          payload: {
            loanAccountNumber: this.property.filterDto.loanAccountNumber,
            dateFrom: this.property.filterDto.dateFrom,
            officeCode: this.property.filterDto.officeCode,
            dateTo: this.property.filterDto.dateTo,
            cifIdName: this.property.filterDto.cifIdName,
            rloanStatusId: 4,
            isRestruct2: true,
            page: 0,
            size: this.property.listElement.loanAccountRestructure.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.loanAccountRestructure =
              resp.data.data.pageResponse.content;
            this.property.listElement.loanAccountRestructure.rows =
              resp.data.data.pageResponse.totalElements;
            this.table.totalRekening = resp.data.data.totalAccount;
            this.table.totalNominatif = resp.data.data.totalNominative;
          } else {
            this.handleFailedGetDataLoanAccountRestructure(resp);
            this.table.totalRekening = 0;
            this.table.totalNominatif = 0;
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorLoanAccountRestructure(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.loanAccountRestructure.downloading = false;
        }, timeout + 500);
      }
    },
    async authorizedLoanAccountRestructure(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Otorisasi Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA_AUTHORIZED",
                endPoint: "loan",
                reqUrl:
                  "transaction-loan/reschedule/authorized/" +
                  props.row.loanAccountId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.filterDto.loanAccountNumber = "";
                  this.property.filterDto.dateFrom = "";
                  this.property.filterDto.dateTo = "";
                  this.property.filterDto.cifIdName = "";
                  this.property.filterDto.officeCode = "";
                  this.property.listElement.loanAccountReschedule.currentPage = 1;
                  this.setDefaultOfficeValue();
                  this.getLoanAccountRestructure();
                }, 1000);
              } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                this.$buefy.dialog.alert({
                  title: "Harap Tunggu !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: `${resp.data.message}`,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? `${error.response.statusText}`
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    routeToPageRincianLoanAccountRestrutcuture(props) {
      this.$router.push(
        `/loan/loan-account-restructure/${this.encryptBASE64(
          `${props.row.loanAccountId}`
        )}`
      );
    },
    handleCatchErrorLoanAccountRestructure(error) {
      console.log(error.response);
      this.table.data.loanAccountRestructure = [];
      this.property.listElement.loanAccountRestructure.rows = 0;
      this.property.listElement.loanAccountRestructure.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async getLoanAccountRestructure() {
      this.table.data.loanAccountRestructure = [];
      this.property.listElement.loanAccountRestructure.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "transaction-loan/reschedule/simple-list",
          payload: {
            loanAccountNumber: "",
            officeCode: this.property.filterDto.officeCode,
            cifIdName: "",
            isRestruct2: true,
            rloanStatusId: 4,
            page: 0,
            size: 10,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.loanAccountRestructure =
              resp.data.data.pageResponse.content;
            this.property.listElement.loanAccountRestructure.rows =
              resp.data.data.pageResponse.totalElements;
            this.table.totalRekening = resp.data.data.totalAccount;
            this.table.totalNominatif = resp.data.data.totalNominative;
          } else {
            this.handleFailedGetDataLoanAccountRestructure(resp);
            this.table.totalRekening = 0;
            this.table.totalNominatif = 0;
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorLoanAccountRestructure(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.loanAccountRestructure.downloading = false;
        }, timeout);
      }
    },
    async handleChangePaginationLoanAccountRestructure(event) {
      this.table.data.loanAccountRestructure = [];
      this.property.listElement.loanAccountRestructure.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "transaction-loan/reschedule/simple-list",
          payload: {
            loanAccountNumber: this.property.filterDto.loanAccountNumber,
            cifIdName: this.property.filterDto.cifIdName,
            dateFrom: this.property.filterDto.dateFrom,
            dateTo: this.property.filterDto.dateTo,
            officeCode: this.property.filterDto.officeCode,
            rloanStatusId: 4,
            isRestruct2: true,
            page: event - 1,
            size: this.property.listElement.loanAccountRestructure.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.loanAccountRestructure =
              resp.data.data.pageResponse.content;
            this.property.listElement.loanAccountRestructure.rows =
              resp.data.data.pageResponse.totalElements;
            this.table.totalRekening = resp.data.data.totalAccount;
            this.table.totalNominatif = resp.data.data.totalNominative;
          } else {
            this.handleFailedGetDataLoanAccountRestructure(resp);
            this.table.totalRekening = 0;
            this.table.totalNominatif = 0;
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorLoanAccountRestructure(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.loanAccountRestructure.downloading = false;
        }, timeout);
      }
    },
    handleFailedGetDataLoanAccountRestructure(resp) {
      this.table.data.loanAccountRestructure = [];
      this.property.listElement.loanAccountRestructure.rows = 0;
      this.property.listElement.loanAccountRestructure.message =
        resp.data.message;
    },
    setDefaultOfficeValue() {
      const getUserDataFromSession = JSON.parse(
        this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
      );
      this.property.filterDto.officeCode =
        getUserDataFromSession.officeCode !==
        this.fetchAppSession("@vue-session/config-bundle")
          .VUE_APP_OFFICE_CENTRAL
          ? getUserDataFromSession.officeCode
          : "";
    },
    onUpdateAo(props) {
      this.$refs.updateAoLoanAccountRestructureDialogRef.onOpenModal({
        accountOfficerIdHandle: props.row.accountOfficerIdHandle,
        collectorId: props.row.collectorId,
        loanAccountId: props.row.loanAccountId,
      });
    },
    onFinishUpdateAO() {
      this.property.listElement.loanAccountRestructure.currentPage = 1;
      this.property.filterDto.loanAccountNumber = "";
      this.property.filterDto.cifIdName = "";
      this.property.filterDto.officeCode = "";
      this.property.filterDto.dateFrom = "";
      this.property.filterDto.dateTo = "";
      this.setDefaultOfficeValue();
      this.property.listElement.loanAccountRestructure.rows = 0;
      this.getLoanAccountRestructure();
    },
    helperGetOfficeDataForReference() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_PARAMETER", {
          params: {
            officeName: "",
            page: 0,
          },
          url: "office",
        })
        .then((response) => {
          Promise.all(
            (this.options.cabang = [{ value: "", text: "-- Pilih --" }]),
            response.data.data.content.map((index) => {
              const value = index.officeCode;
              const text = index.officeName;

              this.options.cabang.push({ text, value });
            })
          ).then(() => {});
        });
    },
  },
  mounted() {
    this.setDefaultOfficeValue();
    this.getLoanAccountRestructure();
    this.helperGetOfficeDataForReference();
  },
};
